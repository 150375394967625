import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import GridContainer from "../sections/GridContainer";
import GridItem from "../sections/GridItem.js";
import Loader from "react-loader-spinner";

const useButtonStyle = makeStyles({
  formField: {
    width: "100%",
    margin: "8px 0",
    padding: 10,
  },
  dateForm: {
    width: "100%",
    margin: "10px 0",
    padding: 10,
  },
  button: {
    background: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 25px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    float: "right",
  },
  success: {
    color: "green",
  },
  label: {
    textTransform: "capitalize",
  },
  error: {
    color: "red",
  },
});
const ContactForm = () => {
  const formClass = useButtonStyle();
  const [success, setSuccess] = useState(false);
  const [time, setTime] = useState(null);
  const [dates, setDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  let dateProps = {
    placeholder: "Preferred Date",
    className: formClass.dateForm,
    initialvalue: new Date(),
    name: "dates",
  };
  let timeProps = {
    placeholder: "Preferred Time",
    className: formClass.dateForm,
    name: "time",
  };
  const changeTime = (time) => {
    setTime(time);
  };
  const changeDate = (date) => {
    setDate(date._d);
    console.log(dates);
  };
  const sendEmail = (values) => {
    values.time = values.time.format("hh:MM A");
    setShowLoader(true);
    fetch("https://cloudville.in/mail/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then(function (response) {
      if (response.status) {
        setSuccess(true);
        setShowLoader(false);
      }
    });
  };
  return (
    <Fragment>
      <div>
        {success ? (
          <span className={formClass.success}>
            Thanks for contacting us, our expert team will reach you on your
            preferred time.Have a good day!
          </span>
        ) : (
          <Fragment>
            <p color="black">Contact Us</p>
            <Formik
              initialValues={{
                name: "",
                email: "",
                mobile: "",
                time: time,
                service: "",
                dates: dates,
              }}
              validate={(values) => {
                const errors = {};
                values.time = time;
                values.dates = dates;
                if (!values.email) {
                  errors.email = "We would love to mail you back";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "A Valid Email Address will be Good";
                }
                if (!values.mobile) {
                  errors.mobile = "Let's Talk";
                } else if (
                  values.mobile.length < 10 ||
                  !Number(values.mobile)
                ) {
                  errors.mobile = "A Valid Mobile will be Good";
                }
                if (!values.time) {
                  errors.time = "We catchup in your Free Time";
                }
                if (!values.dates) {
                  errors.dates = "Choose A Valid Date Please";
                } else if (values.dates < new Date()) {
                  errors.dates = "Choose A Valid Date Please";
                }
                if (!values.service) {
                  errors.service = "Help us to serve you better";
                }
                if (!values.name) {
                  errors.name = "Let's know eachother";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                sendEmail(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    className={formClass.formField}
                    type="text"
                    name="name"
                    placeholder="Name"
                  />
                  <ErrorMessage
                    className={formClass.error}
                    name="name"
                    component="div"
                  />
                  <Field
                    className={formClass.formField}
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    className={formClass.error}
                    name="email"
                    component="div"
                  />
                  <Field
                    className={formClass.formField}
                    type="tel"
                    name="mobile"
                    placeholder="Contact"
                  />
                  <ErrorMessage
                    className={formClass.error}
                    name="mobile"
                    component="div"
                  />
                  <GridContainer>
                    <GridItem xs={6}>
                      <Datetime
                        timeFormat={false}
                        inputProps={dateProps}
                        minDate={new Date()}
                        onChange={(date) => {
                          changeDate(date);
                        }}
                      />
                      <ErrorMessage
                        className={formClass.error}
                        name="dates"
                        component="div"
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <Datetime
                        dateFormat={false}
                        inputProps={timeProps}
                        onChange={(time) => {
                          changeTime(time);
                        }}
                      />
                      <ErrorMessage
                        className={formClass.error}
                        name="time"
                        component="div"
                      />
                    </GridItem>
                  </GridContainer>
                  <Field
                    className={formClass.formField}
                    type="textarea"
                    name="service"
                    placeholder="Services Required"
                  />
                  <ErrorMessage
                    className={formClass.error}
                    name="service"
                    component="div"
                  />
                  <Button
                    type="submit"
                    className={formClass.button}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
            {showLoader ? (
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={10000}
              />
            ) : (
              ""
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ContactForm;
