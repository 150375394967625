import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Solutions We Provide",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Blockchain</h3>
                <p className="m-0">
                  Blockchain can help by providing Auditing, Tamper Proof
                  records, Security, smart Contracts, democratic collaboration
                  and data authenticity. Blockchain is not only used for
                  cryptocurrency, it can be used for different purposes. In this
                  digital transformation world blockchain assure the data
                  security and tamper proof. We are expert in handling the
                  blockchain to different domains by implementing blockchain for
                  different domains like Supply Chain Management, Banking,
                  Investment Banking, Pharmaceuticals and FMCG. Our team is
                  expertise in providing end to end solution in building
                  blockchain chain application.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/blockchain.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Website Development</h3>
                <p className="m-0">
                  Website is one of the key identity for any business, there are
                  different options in developing a website, we are expert in
                  developing custom, dynamic website and converts that to a
                  business model. Managing website is very essential in this
                  transformation world. We are here to help in developing,
                  supporting and keep it updated..
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/website.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Data Management</h3>
                <p className="m-0">
                  Data is the key success for any business, without data there
                  is no business model. We are expertise in handling data from
                  different sources, different formats for different domains. We
                  convert your data to a meaningful statements to make some
                  business decisions. We are expert in handling legacy data from
                  any format to latest format. We are expert in developing end
                  to end solution for handling data in different domains like
                  Supply Chain Management, Banking and Financial services,
                  Retail Market, Manufacturing, Hotel and Travel,
                  Pharmaceuticals and Logistics.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/data.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Data Analytics</h3>
                <p className="m-0">
                  The Analytics are the key success for any business. Our
                  expertise team helps you to convert your data to Financial
                  reports. We helps you to improve the efficiency and profit by
                  taking some important business decisions with our Analytics.
                  We are expert in building dashboards which helps you to keep
                  monitoring on your business even from your handy systems like
                  mobile, tablets and Ipads. We are expert in developing Data
                  Analytical solutions in different domains like Supply Chain
                  Management, Banking and Financial services, Retail Market,
                  Manufacturing, Hotel and Travel, Pharmaceuticals and
                  Logistics.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/analytics.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Artificial Intelligence</h3>
                <p className="m-0">
                  The Artificial Intelligence will help to elevate your business
                  to next level. We are expert in implementing AI and build
                  predictions to elevate your business. Our predictions helps
                  you to take action on the key area to gain efficiency in your
                  business. We are expert in implementing AI in different
                  domains like Supply Chain Management, Banking and Financial
                  services, Retail Market, Manufacturing, Hotel and Travel,
                  Pharmaceuticals and Logistics.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/aiml.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Digital Transformation</h3>
                <p className="m-0">
                  Transformation is never ending, we are expert in handling this
                  transformation. We help to convert your legacy system to
                  digitalised system. Our expertise team help you to upgrade
                  your business by digitalising from old notebooks ledger, we
                  also help you to develop mobile applications and upgrade your
                  business. We are expert in digitilizating different domains
                  like Supply Chain Management, Banking and Financial services,
                  Retail Market, Manufacturing, Hotel and Travel,
                  Pharmaceuticals and Logistics.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/digital.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12 text-color-primary">Data Center</h3>
                <p className="m-0">
                  We provide the end to end solutions for your data center
                  needs. We advise the right solution for Enterprise, Start-ups
                  and Developer. We partner with you to find or create a hosting
                  solution that works best for your business and we will apply
                  all our extensive knowledge, high-performance hardware and
                  global data centres to help you with your infrastructure
                  needs. Out team is available 24/7 to help you.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/datacentre.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
